<template>
  <cw-page
    icon="check"
    class="cw-brokering-completed-offers"
  >
    <template #hero-title>
      <translate>
        Thank you for choosing our service!
      </translate>
    </template>

    <template>
      <v-row
        justify="left"
      >
        <v-col
          cols="12"
          md="6"
        >
          <p class="mb-6">
            <translate>
              Thank you for choosing our service and welcome to Saldo!
            </translate>
          </p>
        </v-col>
      </v-row>

      <v-row
        justify="left"
      >
        <v-card-text class="text-xs-left">
          <p>
            <translate>
              You have chosen a preliminary offer from our partner.
              You will be redirected to finish the application in their service.
            </translate>
          </p>
        </v-card-text>
      </v-row>
    </template>

    <template #actions>
      <v-spacer/>
      <v-btn
        v-if="product.ownPagesUrl"
        id="submit"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          goToRedirectUrl();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Continue</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CwBrokeringCompletedOffers',

  props: {
    timeout: {
      type: Number,
      default: 10000,
    },
  },

  data: () => ({
    loadingData: false,
    redirect: null,
    seconds: 0,
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      product: 'application/getProduct',
      redirectUrl: 'application/getOfferRedirectUrl',
    }),

    brand() {
      if (!this.product || !this.product.brand) return '';
      return this.product.brand.toLowerCase();
    },
  },

  created() {
    // placeholder
  },

  mounted() {
    this.automaticRedirect();
  },

  beforeDestroy() {
    clearTimeout(this.redirect);
  },

  methods: {
    ...mapActions({
      getApplication: 'application/getApplication',
    }),

    automaticRedirect() {
      this.seconds = this.timeout / 1000;
      this.redirect = setTimeout(async () => {
        this.goToRedirectUrl();
      }, this.timeout);
    },

    goToRedirectUrl() {
      window.location = this.getRedirectUrl();
    },

    getRedirectUrl() {
      this.getApplication();
      return this.initialData.offerRedirectUrl;
    },
  },
};
</script>
